* {
  font-size: 15px !important;
}

body {
  margin: 12px;
}

/* login */
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* home */
.btnicon {
  display: flex;
}

.downloadPDF {
  display: flex;
  justify-content: center;
}

/* .btnPDF{
  display: contents;
} */

/* navbar */
a {
  color: white;
}

a.menu_link {
  color: white;
  border-bottom: 2px solid white;
}

.MuiTypography-h4 a {
  text-decoration: none;
}

.badge.bg-danger.count-cartitem {
  padding: 5px;
  font-size: small;
  vertical-align: top !important;
  border-radius: 50%;
  height: 21px;
}

.MuiTypography-h4 a:hover {
  color: white;
}

.MuiTypography-body1 {
  font-size: 12px !important;
}

/* adduser */
.close {
  justify-content: right;
  display: flex;
  opacity: 1.5;
}

.error {
  color: red;
  margin-bottom: 0;
}

.MuiTableSortLabel-active {
  background: #6c757d !important;
  padding: 2px !important;
  border-radius: 4px !important;
  color: #fff !important;
}