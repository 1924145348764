.container-register {
  background-color: #3c3c42;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 465px;
  width: 350px;
  box-shadow: -3px -2px 6px #6c6565, 7px 9px 25px #0b0b0b;
  border-radius: 10px;
}

.signup-title {
  margin: 20px;
  font-size: 30px;
  color: white;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-input {
  height: 30px;
  border: 2px solid #e1dede69;
  border-radius: 5px;
  background-color: #777272;
  color: black;
  text-align: center;
  outline: none;
  font-size: 15px;
}

.register-input::placeholder {
  color: black;
  opacity: 1;
}

.form-item {
  margin: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-item input {
  margin: 5px;
  width: 100%;
}

.form-btns {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.form-btns button {
  margin: auto;
  font-size: 20px;
  padding: 8px;
  width: 280px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(75, 61, 61);
  background: #fbba50;
}

.form-btns button:disabled {
  cursor: not-allowed;
  background: #8f8b85 !important;
  color: rgb(131, 120, 120) !important;
}

.form-btns button:hover {
  background: #d88a0c;
  color: antiquewhite;
}