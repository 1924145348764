body {
  padding: 0;
  margin: 0;
}

.background {
  background: url('../img/background-img.jpg') rgba(0, 0, 0, 0.61);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  filter: blur(3px);
  /* min-height: 800px; */
}

.login-title {
  font-size: 30px;
  color: white;
  margin: 20px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: transparent;
  box-shadow: 0 0 15px rgb(255, 255, 255);
  border-radius: 15px;
  width: 500px;
  margin: 10vh auto;
}

.login-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 85%;
}

.form-item {
  display: flex;
  width: 90%;
  background: transparent;
  margin: 10px auto;
  align-items: center;
  border-radius: 15px;
  border-bottom: 1px solid rgb(82, 81, 81);
}

.form-item:hover {
  border: 1px solid rgb(82, 81, 81);
}

.login-input {
  font-size: 18px;
  width: 80%;
  height: 30px;
  outline: none;
  background: transparent;
  border: none;
  margin: auto;
  color: white;
}

/* span {
  margin: 5px;
  color: rgb(172, 172, 172);
  cursor: default;
  user-select: none;
  background: rgba(85, 81, 81, 0.637);
  padding: 5px;
  border-radius: 15px;
} */

.login-btn {
  width: 250px;
  font-size: 20px;
  margin: 10px auto;
  padding: 10px 16px;
  color: white;
  background: rgba(122, 123, 116, 0.72);
  border: none;
  text-align: center;
  transition: all 0.7s ease;
  border-radius: 10px;
}

.login-btn:hover {
  background: rgb(172, 134, 8);
}